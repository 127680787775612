import * as React from "react"

import "./master.scss"

const Master = ({ children }) => {
    return(
        <>
            {children}
        </>
    );
};

export default Master;
