import { Link } from "gatsby"
import * as React from "react"

const Header = () => {
    return(
        <>
            <h1 className="page-title">Pinnacle Sports<br />Guide Kit Checkin/Checkout</h1>

            <ul className="nav-buttons">
                <li><Link to="/"><button>New Entry</button></Link></li>
                <li><Link to="/log"><button>Log</button></Link></li>
                <li><Link to="/kits"><button>Kit List</button></Link></li>
            </ul>

            <hr />
        </>
    );
};

export default Header;